import { cssRule } from 'typestyle';

// import { fontFace } from 'typestyle';
// fontFace({
//   fontFamily: 'Roboto',
//   fontStyle: 'normal',
//   fontWeight: 400,
//   src: `/fonts/Roboto/Roboto-Regular.ttf`,
// });

cssRule('*', {
  boxSizing: 'border-box',
});

cssRule('body', {
  fontFamily: "'Inter', sans-serif",
  fontSize: 14,
  margin: 0,
  padding: 0,
});
