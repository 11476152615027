import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { colors } from '@hulanbv/dashboard-components/distribution/domain/constants/colors.constants';
import { reportWebVitals } from './report-web-vitals';
import './styles/global.styles';

colors.primary = '#1678F2';

const render = async () => {
  const { Main } = await import('./components/main');
  return ReactDOM.render(
    <StrictMode>
      <Main />
    </StrictMode>,
    document.getElementById('root'),
  );
};
render();
reportWebVitals();
